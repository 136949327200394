import gsap from "gsap";

let imagetrail = false;

// Image trail: function load
export default function load_imagetrail(element) {
    let section = document.querySelector(element);
    section.classList.add("trail-container");

    const trailElement = document.querySelector(".trail-container");

    trailElement.removeEventListener("mousemove", _listener, true);

    // helper functions
    const MathUtils = {
        // linear interpolation
        lerp: (a, b, n) => (1 - n) * a + n * b,
        // distance between two points
        distance: (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1),
    };

    // get the mouse position
    const getMousePos = (ev) => {
        let posx = 0;
        let posy = 0;
        if (!ev) ev = window.event;

        posx = ev.clientX;
        posy = ev.clientY - trailElement.getBoundingClientRect().top;

        return {
            x: posx,
            y: posy,
        };
    };

    let mousePos = {
        x: 0,
        y: 0,
    };
    let lastMousePos = {
        x: 0,
        y: 0,
    };
    let cacheMousePos = {
        x: 0,
        y: 0,
    };

    var _listener = (ev) => (mousePos = getMousePos(ev));
    trailElement.addEventListener("mousemove", _listener, true);
    const getMouseDistance = () =>
        MathUtils.distance(mousePos.x, mousePos.y, lastMousePos.x, lastMousePos.y);

    // Image trail: Image class
    class Image {
        constructor(el) {
            this.DOM = {
                el: el,
            };
            // image deafult styles
            this.defaultStyle = {
                scale: 1,
                x: 0,
                y: 0,
                opacity: 0,
            };
            // get sizes/position
            this.getRect();
            // init/bind events
            this.initEvents();
        }
        initEvents() {
            // on resize get updated sizes/position
            window.addEventListener("resize", () => this.resize());
        }
        resize() {
            // reset styles
            gsap.set(this.DOM.el, this.defaultStyle);
            // get sizes/position
            this.getRect();
        }
        getRect() {
            this.rect = this.DOM.el.getBoundingClientRect();
        }
        isActive() {
            // check if image is animating or if it's visible
            return gsap.isTweening(this.DOM.el) || this.DOM.el.style.opacity != 0;
        }
    }

    // Image trail: Image trail class
    class ImageTrail {
        constructor() {
            // images container
            this.DOM = {
                content: trailElement,
            };

            // array of Image objs, one per image element
            this.images = [];
            [...this.DOM.content.querySelectorAll(".intro-imgtrail__img")].forEach((img) =>
                this.images.push(new Image(img))
            );
            // total number of images
            this.imagesTotal = this.images.length;
            // upcoming image index
            this.imgPosition = 0;
            // zIndex value to apply to the upcoming image
            this.zIndexVal = 1;
            // mouse distance required to show the next image
            this.threshold = 190;
            // render the images
            this.raf();
        }
        render() {
            // get distance between the current mouse position and the position of the previous image
            let distance = getMouseDistance();
            // cache previous mouse position
            cacheMousePos.x = MathUtils.lerp(
                cacheMousePos.x || mousePos.x,
                mousePos.x,
                0.1
            );
            cacheMousePos.y = MathUtils.lerp(
                cacheMousePos.y || mousePos.y,
                mousePos.y,
                0.1
            );

            // if the mouse moved more than [this.threshold] then show the next image
            if (distance > this.threshold) {
                this.showNextImage();

                ++this.zIndexVal;
                this.imgPosition =
                    this.imgPosition < this.imagesTotal - 1 ? this.imgPosition + 1 : 0;

                lastMousePos = mousePos;
            }

            // check when mousemove stops and all images are inactive (not visible and not animating)
            let isIdle = true;
            for (let img of this.images) {
                if (img.isActive()) {
                    isIdle = false;
                    break;
                }
            }
            // reset z-index initial value
            if (isIdle && this.zIndexVal !== 1) {
                this.zIndexVal = 1;
            }

            // loop..
            this.raf();
        }

        raf() {
            this.rAF = requestAnimationFrame(() => this.render());
        }

        cancel() {
            window.cancelAnimationFrame(this.rAF);
        }

        showNextImage() {
            // show image at position [this.imgPosition]
            const img = this.images[this.imgPosition];
            // kill any tween on the image
            gsap.killTweensOf(img.DOM.el);

            new gsap.timeline()
                // show the image
                .set(
                    img.DOM.el, {
                        startAt: {
                            opacity: 0,
                            scale: 1,
                        },
                        opacity: 1,
                        scale: 1,
                        zIndex: this.zIndexVal,
                        x: cacheMousePos.x - img.rect.width / 2,
                        y: cacheMousePos.y - img.rect.height / 2,
                    },
                    "0"
                )
                // animate position
                .to(img.DOM.el, {
                    ease: "circ.out",
                    x: mousePos.x - img.rect.width / 2,
                    y: mousePos.y - img.rect.height / 2,
                    duration: 1.8,
                })
                // then make it disappear
                .to(
                    img.DOM.el, {
                        ease: "expo.inOut",
                        delay: 0.2,
                        opacity: 0,
                        duration: 1,
                    },
                    "0.4"
                )
                // scale down the image
                .to(
                    img.DOM.el,
                    1, {
                        ease: "expo.inOut",
                        delay: 0.4,
                        scale: 0.2,
                        duration: 0.8,
                    },
                    "0.4"
                );
        }
    }

    // Image trail: Init
    if (!imagetrail) {
        imagetrail = new ImageTrail();
    } else {
        imagetrail.cancel();
        imagetrail = new ImageTrail();
    }
}