// Dependency
import {
    Utility
} from "./config/animation";
import Smoothscroll from './locomotive';
import Default from './config/default';
import Trail from './plugin/imgtrail';
import Intro from "./intro";


export default function Init() {
    Default();
    Smoothscroll();
    Utility();
    Intro();

    // Image trail desktop only
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        try {
            Trail("#intro");
        } catch (error) {}
    }
}

Init();