import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Scrollbar from 'smooth-scrollbar';
import configAnimation from "./config/animation";

let elScroller = configAnimation.global_scroller;

export default function Smoothscroll() {
    // Setup
    const scroller = document.querySelector(elScroller);

    const bodyScrollBar = Scrollbar.init(scroller, {
        damping: 0.08,
        delegateTo: document,
    });

    bodyScrollBar.track.xAxis.element.remove()

    ScrollTrigger.scrollerProxy(elScroller, {
        scrollTop(value) {
            if (arguments.length) {
                bodyScrollBar.scrollTop = value;
            }
            return bodyScrollBar.scrollTop;
        }
    });

    bodyScrollBar.addListener(ScrollTrigger.update);

}