import gsap from "gsap";
import { anim_revealBottom } from "./config/animation";

export default function Intro() {
    try {
        shopEnter();
    } catch (error) {
        console.log(error);
    }
}


function shopEnter() {
    const elBtn = document.querySelector(".intro-btn");

    function removeSection() {
        gsap.to("#intro", {
            opacity: 0,
            display: "none"
        });
    }

    elBtn.addEventListener("click", removeSection);
}